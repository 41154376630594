import React, { useMemo } from 'react'
import ReactPlayer from 'react-player/file'

export default function VideoPlayer({ className, url, ...props }) {
  const computedClassName = useMemo(() => {
    return [className, 'w-100', 'h-100', 'bg-video'].join(' ')
  }, [className])

  return (
    <ReactPlayer className={computedClassName} playing={true} muted={true} playsinline={true} loop={true} url={url} {...props} />
  )
}
