import React, { memo, useState } from 'react'
import VideoPlayer from './videoPlayer'
import { GatsbyImage } from 'gatsby-plugin-image'

function VideoOrImage({ videoWhen, image, video, height, width, mobileImage, mobileVideo, className, ...props }) {
    const [ready, setReady] = useState(false)

    if (videoWhen) {
        return (
            <div style={{ opacity: (ready ? 1 : 0) }} className='fade-in bg-image fade-image' >
                {mobileVideo ? <VideoPlayer className='m-show' onReady={() => setReady(true)} height={height ? height : '100%'} width={width ? width : '100%'} url={mobileVideo} {...props} /> : null}
                <VideoPlayer onReady={() => setReady(true)} className={(mobileVideo ? 'm-hide' : '')} height={height ? height : '100%'} width={width ? width : '100%'} url={video} {...props} />
            </div>
        )
    }
    return (
        <>
            {mobileImage ? <GatsbyImage className={'m-show fade-image  ' + className} alt='' image={mobileImage?.gatsbyImageData} {...props} /> : null}
            <GatsbyImage  className={'fade-image ' + className + ' ' + (mobileImage ? 'm-hide' : '')} alt='' image={image?.gatsbyImageData} {...props} />
        </>
    )
}

export default memo(VideoOrImage)

