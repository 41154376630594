import React, { createContext, useContext, useState } from 'react'


const defaultLocationContext = {
    next: 'Open Finance',
    setNext: () => { },
    running: false,
    setRunning: () => { }
}

export const LocationContext = createContext(defaultLocationContext)

export default function LocationContextWrapper({ children }) {
    const [next, setNext] = useState(defaultLocationContext.next)
    const [running, setRunning] = useState(defaultLocationContext.running)
    return (
        <LocationContext.Provider value={{ next, setNext, setRunning, running }}>
            {children}
        </LocationContext.Provider>
    )
}

export const usePageContext = () => {
    const { setNext, next } = useContext(LocationContext)
  
    return { setNext, next }
}

export const useTransitionContext = () => {
    const { setRunning, running } = useContext(LocationContext)
    return { setRunning, running }
}