import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { useLanguage } from "../hooks/useLanguage"

export default function Announcement() {
    const { pathname } = useLocation()
    const { switchLanguage } = useLanguage()
    const [isAnnouncementsOpen, setIsAnnouncementsOpen] = useState(pathname === "/")

    const { announcement } = useStaticQuery(graphql`
        query AnnouncementQuery {
            announcement: datoCmsAnnouncement {
                title
                titleJp
                link
                buttonText
                buttonTextJp
                showAnnouncement
                backgroundColour {
                    hex
                }
            }
        }
    `)

    const { backgroundColour, title, titleJp, buttonTextJp, buttonText, link } = announcement

    const closeAnnouncement = () => setIsAnnouncementsOpen(false)

    useEffect(() => {
        if (pathname !== "/") return setIsAnnouncementsOpen(false)
    }, [pathname])

    if (!announcement.showAnnouncement) return null

    return (
        <aside className={`announcement ${isAnnouncementsOpen ? "open" : "close"} text-white p60 pt20 pb20 m-p20`} style={{ backgroundColor: backgroundColour?.hex }}>
            <div className='pos-rel max-1600 ma w-100 inner flex align-center gap-20'>
                <div className='mla mra flex align-center gap-10 m-wrap'>
                    <p>{switchLanguage(titleJp, title)}</p>
                    <a href={link} target='_blank' rel='noopener noreferrer' className='ul-link op-80'>
                        {switchLanguage(buttonTextJp, buttonText)}
                    </a>
                </div>
                <button className='close-btn' onClick={closeAnnouncement}>
                    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0.353516 0.389768L13.2229 13.2591M13.3535 0.353577L0.353517 13.3536' stroke='white' />
                    </svg>
                </button>
            </div>
        </aside>
    )
}
