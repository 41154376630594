import React, { createContext, useEffect, useState } from "react"
export const LanguageContext = createContext({})

export default function LanguageContextProvider({ children }) {
    const [isJapanese, setIsJapanese] = useState(false)

    useEffect(() => {
        if (!window) return
        const query = window.location.search.split("=")[1]
        console.log(query)
        if (query === "jp") {
            sessionStorage.setItem("japanese", true)
            setIsJapanese(true)
        } else return
    }, [])

    useEffect(() => setIsJapanese(() => Boolean(sessionStorage.getItem("japanese"))), [isJapanese])

    const stateProps = { isJapanese, setIsJapanese }

    return <LanguageContext.Provider value={stateProps}>{children}</LanguageContext.Provider>
}
