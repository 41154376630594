import gsap from "gsap"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import Button from "./button"
import Link from "./link"

import { graphql, useStaticQuery } from "gatsby"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { usePageContext, useTransitionContext } from "../context/locationContext"
import { COLOR_STATES, useHeaderColorMode } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"
import useProductsData from "../hooks/useProductsData"
import { ReactComponent as Fsco } from "../images/fsco.svg"
import MenuLinks from "./menuLinks"
/* performance optimisation still needed */

const Header = ({ title, japaneseTitle }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [menuOpenRunning, setMenuOpenRunning] = useState(false)
    const { isDark, colorMode } = useHeaderColorMode()
    const { switchLanguage, isJapanese } = useLanguage()

    const { setNext, next } = usePageContext()
    const { running } = useTransitionContext()

    const { enquire } = useStaticQuery(graphql`
        query EnquireBtnQuery {
            enquire: datoCmsEnquireButton {
                title
                titleJp
                link
            }
        }
    `)

    //title not included in order to let next be cached until page change

    useLayoutEffect(() => {
        if (!running && isJapanese) {
            setTimeout(() => {
                return setNext(() => switchLanguage(japaneseTitle, title))
            }, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [running, setNext, isJapanese])

    const finishedRunningAndUpdated = useMemo(() => {
        if (!running && title === next) {
            return true
        }
        return false
    }, [running, title, next])

    const tl = useRef(null)

    useEffect(() => {
        playInitAnimationOnTl(
            tl,
            () => setMenuOpenRunning(true),
            () => setMenuOpenRunning(false)
        )
    }, [])

    const openMenu = useCallback(() => {
        tl.current.play()
        document.querySelector(".overlay--menu").classList.add("active")
    }, [])

    const closeMenu = useCallback(() => {
        tl.current.reverse()
        document.querySelector(".overlay--menu").classList.remove("active")
    }, [])

    const overlayMenuState = useMemo(() => {
        return !menuOpen ? "" : "active"
    }, [menuOpen])

    useEffect(() => {
        if (tl.current) {
            if (menuOpen) {
                openMenu()
            } else {
                closeMenu()
            }
        }
    }, [menuOpen, openMenu, closeMenu])

    const menuToggle = useCallback(() => {
        if (!tl.current.isActive()) {
            setMenuOpen(state => !state)
            setMenuOpenRunning(true)
            setTimeout(() => {
                setMenuOpenRunning(false)
            }, tl.current.duration() * 1000 - 325)
        }
    }, [setMenuOpen])

    useEffect(() => {
        if (running) {
            closeMenu()
            setMenuOpen(false)
        }
    }, [running, closeMenu])

    const { products, links } = useProductsData()

    const bgColor = useMemo(() => {
        if (menuOpen || menuOpenRunning || colorMode === COLOR_STATES.DARK_NO_BG) {
            return ""
        }
        if (colorMode === COLOR_STATES.DARK_WHITE_BG.name) {
            return "bg-white "
        }
        if (colorMode === COLOR_STATES.DARK.name) {
            return "bg-light "
        }
        if (isDark) {
            return "m-bg-white "
        }
        return
    }, [isDark, menuOpen, colorMode, menuOpenRunning])

    return (
        <>
            <header className={"masthead m-bw1 btt  transition-bgColor z-2 " + bgColor}>
                <div className={"overlay--background z-2 " + overlayMenuState} role='presentation' onClick={menuToggle} />
                <div className={"flex p60 pt20 pb20 max-1600 ma m-p20 m-pt10 m-pb10  z-3 pos-rel "}>
                    <Link to='/' className='logo--wrapper link flex align-center text-white pointer-all z-3'>
                        <div className={"logo--main mr5 " + (isDark && !menuOpen && !menuOpenRunning ? "dark" : "")}>
                            <Fsco />
                        </div>
                        <div className='flex flex-col justify-end overflow-hidden no-wrap trans-1px' style={{ height: "50%" }}>
                            <div className={!finishedRunningAndUpdated ? "transition-page-name" : "delay-1"} style={{ minHeight: "200%", transform: !finishedRunningAndUpdated ? "translateY(calc(50% - 0.5px))" : "" }}>
                                <p className={"m0 logo-text " + (isDark && !menuOpen && !menuOpenRunning ? "text-grey" : "")}>{switchLanguage(japaneseTitle, title)} </p>
                                <p className={"m0 logo-text " + (isDark && !menuOpen && !menuOpenRunning ? "text-grey" : "")}>{next}</p>
                            </div>
                        </div>
                    </Link>
                    <div className={"flex justify-end pointer-all flex align-center mla z-3  " + (isDark && !menuOpen && !menuOpenRunning ? "" : "text-white")}>
                        <Button as={Link} to={enquire.link} text={switchLanguage(enquire.titleJp, enquire.title)} className={"outline mla mr10 m-hide transition-border hover-green border no-wrap " + (!isDark && !menuOpen ? "no-outline" : "")} />
                        <Button
                            className={"menu--button hover-green border " + (isDark && !menuOpen ? "dark " : "")}
                            text={
                                <div className='flex justify-center align-center'>
                                    <SwitchTransition>
                                        <CSSTransition classNames='fade-quick' timeout={200} key={menuOpen}>
                                            <span>{menuOpen ? switchLanguage("閉じる", "Close") : switchLanguage("メニュー", "Menu")}</span>
                                        </CSSTransition>
                                    </SwitchTransition>

                                    <div className={"menu-toggle " + (menuOpen ? "open" : "closed")}>
                                        <div className={"middle-toggle " + (menuOpen ? "open" : "closed")} />
                                    </div>
                                </div>
                            }
                            role='presentation'
                            onClick={menuToggle}
                        />
                    </div>
                    <MenuLinks products={products} links={links} />
                </div>
            </header>
        </>
    )
}

function playInitAnimationOnTl(tl, onStart, onComplete) {
    tl.current = gsap.timeline({ paused: true, onStart, onComplete })
    tl.current.to(".overlay--background", { opacity: 1, duration: 0.75, ease: "ease" })
    tl.current.fromTo(".overlay--menu", { height: 1 }, { height: 450, duration: 0.75, backdropFilter: "blur(100px)", ease: "power3.inOut" }, "-=0.75")
    tl.current.fromTo(".overlay--menu .inner", { opacity: 0 }, { opacity: 1, duration: 1, ease: "power3.inOut" }, "-=0.5")
    tl.current.fromTo(".menu-links", { display: "block", opacity: 1 }, { display: "none", opacity: 0, duration: 0.35, ease: "ease" }, "<")
    tl.current.fromTo(".menu--title", { display: "none", opacity: 0 }, { display: "block", opacity: 1, duration: 0.35, ease: "ease" }, ">")
}

export default Header
