import { useContext } from "react"
import { LanguageContext } from "../context/languageContext"

export const useLanguage = () => {
    const { isJapanese, setIsJapanese } = useContext(LanguageContext)

    const isLanguageJapanese = boolean => {
        if (boolean) {
            setIsJapanese(true)
            sessionStorage.setItem("japanese", true)
        } else {
            setIsJapanese(false)
            sessionStorage.setItem("japanese", "")
        }
    }

    const switchLanguage = (japanese, english) => (isJapanese ? japanese || english : english)

    return { isJapanese, isLanguageJapanese, switchLanguage }
}
