import { useMemo } from 'react'

export default function useMobile() {
    const mobile = useMemo(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                return true
            } else {
                return false
            }
        }
        return null;
    }, [])

    return mobile
}
