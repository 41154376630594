const React = require("react")
const smoothscroll = require("smoothscroll-polyfill")
const LanguageContextProvider = require("./src/context/languageContext").default
const LocationContextWrapper = require("./src/context/locationContext").default
const Header = require("./src/components/header").default
const Announcement = require("./src/components/announcement").default
const MenuContextWrapper = require("./src/context/menuContext").default

exports.onClientEntry = () => {
    smoothscroll.polyfill()
}

exports.wrapPageElement = ({ element, props }) => {
    const { data } = props
    let title = data?.pageData?.subtitle
    const japaneseTitle = data?.pageData?.subtitleJap || data?.pageData?.subtitleJp
    if (window.innerWidth < 768 && data.pageData.subtitle === "Bridging Data & Capital") {
        title = "ㅤㅤㅤㅤ⠀"
    }

    return (
        <>
            <Announcement />
            <Header title={title || "Open Finance"} japaneseTitle={japaneseTitle} />
            {element}
        </>
    )
}

exports.wrapRootElement = ({ element }) => {
    return (
        <MenuContextWrapper>
            <LanguageContextProvider>
                <LocationContextWrapper>{element}</LocationContextWrapper>
            </LanguageContextProvider>
        </MenuContextWrapper>
    )
}
