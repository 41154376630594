import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";


export const COLOR_STATES = {
    LIGHT: {
        name: 'LIGHT',
        isDark: false
    },
    DARK: {
        name: 'DARK',
        isDark: true
    },
    DARK_WHITE_BG: {
        name: 'DARK_WHITE_BG',
        isDark: true
    },
    DARK_NO_BG: {
        name: 'DARK_NO_BG',
        isDark: true
    }
}

const defaultMenuContext = {
    colorMode: COLOR_STATES.LIGHT.name,
    setColorMode: () => { }
}

export const MenuContext = createContext(defaultMenuContext)

const MenuContextWrapper = ({ children }) => {
    const [colorMode, setColorMode] = useState(defaultMenuContext.colorMode)

    useEffect(() => {
    }, [colorMode])

    return (
        <MenuContext.Provider value={{ colorMode, setColorMode }}>
            {children}
        </MenuContext.Provider>
    )
}

export const useChangeInView = ({ inital = false, exitTo, enterTo, ...options }) => {
    const { ref, inView } = useInView({ initialInView: inital, ...options })
    const { setColorMode } = useContext(MenuContext)

    useEffect(() => {

        if (inView && enterTo) {
            return setColorMode(enterTo.name)
        } else if (!inView && exitTo) {
            return setColorMode(exitTo.name)
        }
    }, [inView, setColorMode, enterTo, exitTo])

    return { ref }

}




export const useHeaderColorMode = () => {
    const { colorMode, } = useContext(MenuContext)
    const isDark = useMemo(() => {
        if (COLOR_STATES[colorMode].isDark) {
            return true
        }
        return false
    }, [colorMode])

    return { colorMode, isDark }
}

export default MenuContextWrapper