import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"
import { useTransitionContext } from "../context/locationContext"
import useMobile from "../hooks/useMobile"

export default function Link({ children, ...props }) {
  const { setRunning } = useTransitionContext()

  const mobile = useMobile()

  const entry = {
    length: 2,
    delay: mobile ? 0.85 : 0,
  }

  const exit = {
    length: 2,
  }

  const onEnd = () => {
    setRunning(false)
  }
  const trigger = async pages => {
    const tl = gsap.timeline({ onStart: () => setRunning(true), onComplete: onEnd })
    const exit = await pages.exit

    if (mobile) {
      tl.to(exit.node, { opacity: 0, duration: 0.85, ease: "ease" })
    } else {
      tl.to(exit.node.querySelector(".scrolling-wrapper"), { duration: 0.85, borderRadius: "20px", scale: 0.96, ease: "power4.inOut" }, "<")
      tl.fromTo(exit.node.querySelector(".dark-wrapper"), { filter: "brightness(1)" }, { filter: "brightness(0.5)", duration: 0.65, ease: "power4.inOut" }, "<")
    }
    const entry = await pages.entry
    tl.to(entry.node, { overflow: "hidden", borderRadius: "20px", duration: 0 }, mobile ? "+=0" : "-=0.5")
    tl.fromTo(entry.node, { transform: `translateY(100vh)`, opacity: mobile ? 0 : 1 }, { transform: `translateY(0vh)`, duration: 0.85, scale: 1, opacity: 1, ease: "power3.inOut" }, "<")

    tl.to(entry.node, { borderRadius: "0px", duration: 0, ease: "power4.inOut" }, "<")
  }

  return (
    <TransitionLink exit={exit} entry={entry} trigger={trigger} {...props}>
      {children}
    </TransitionLink>
  )
}
