import { graphql, useStaticQuery } from "gatsby"

export default function useProductsData() {
    const { products, menuLinks } = useStaticQuery(graphql`
        query Products {
            menuLinks: allDatoCmsMenuLink(sort: {fields: position, order: ASC}) {
                nodes {
                    id
                    title
                    titleJp
                    link
                }
            }
            products: allDatoCmsOffering {
                nodes {
                    name
                    nameJp
                    featuredVideoUrl
                    featuredImage {
                        gatsbyImageData
                    }
                    slug
                }
            }
        }
    `)
    return { products, links: menuLinks.nodes }
}
