import React, { memo, useCallback, useState } from "react"
import { useLanguage } from "../hooks/useLanguage"
import Link from "./link"
import VideoOrImage from "./videoOrImage"

function MenuLinks({ products, links }) {
    const [productTab, setProductTab] = useState(false)
    const { switchLanguage } = useLanguage()
    const openProductTab = useCallback(() => {
        document.querySelector(".menu--link.active")?.classList.remove("active")
        return setProductTab(true)
    }, [])

    const closeProductTab = useCallback(() => {
        return setProductTab(false)
    }, [])

    console.log({ links })
    return (
        <div className='overlay--menu z-2'>
            <div className='inner p60 pt120 w-100 mta max-1600 ma grid grid-cols-auto-1 justify-between m-p20'>
                <div className=''>
                    {links.map(link => {
                        if (link.title === "Products")
                            return (
                                <button key={link.id} onMouseEnter={openProductTab} className={"menu--link m-hide circle-link link h3 " + (productTab ? "active" : "")} to={link.link}>
                                    <span>{switchLanguage(link.titleJp, link.title)} </span>
                                </button>
                            )
                        if (link?.title?.includes("ESG") || link?.title?.includes("Assets") || link?.title?.includes("AI"))
                            return (
                                <Link key={link.id} className='menu--link m-show circle-link link h3' activeClassName='active' to={link.link}>
                                    <span>{switchLanguage(link.titleJp, link.title)} </span>
                                </Link>
                            )
                        return (
                            <Link key={link.id} onMouseEnter={closeProductTab} className='menu--link circle-link link h3' activeClassName='active' to={link.link}>
                                <span>{switchLanguage(link.titleJp, link.title)} </span>
                            </Link>
                        )
                    })}
                </div>

                <div className={"flex w-100 justify-end products-thumbnail " + (productTab ? "active" : "")}>
                    {products.nodes.map((product, index) => {
                        return (
                            <Link className='menu--product max-250 w-100 ml30 hover-image--zoom' to={`/products/${product.slug}`} key={index}>
                                <div className='bg-grey ratio-3-2 br-10 overflow-hidden'>
                                    <VideoOrImage className='bg-image' video={product.featuredVideoUrl} videoWhen={product.featuredVideoUrl} image={product.featuredImage} />
                                </div>
                                <div className='pt20 pb20 bb1g text-white'>
                                    <p className='m0 arrow-link'>{switchLanguage(product.nameJp, product.name)}</p>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default memo(MenuLinks)
